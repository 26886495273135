<template>
  <div class="flex flex-col flex-grow relative">
    <div class="flex mt-6 mb-10 flex-grow">
      <div class="w-full md:w-2/3 md:mx-auto md:max-w-md px-4">
        <h1 class="my-4 text-3xl">
          Login
        </h1>

        <div
          v-if="form.errors.any()"
          class="text-sm bg-blue-50 border p-4 mb-4 rounded text-blue-800 dark:bg-blue-900  border-blue-700"
        >
          Please login using your AirFormTable credentials. Airtable and AirFormTable
          are not the same entity, so you do need to create a AirFormTable account
          if you don't have one.
        </div>
        <form
          @submit.prevent="login"
          @keydown="form.onKeydown($event)"
        >
          <!-- Email -->
          <text-input
            name="email"
            :form="form"
            label="Email"
            :required="true"
          />

          <!-- Password -->
          <text-input
            class="mt-3"
            native-type="password"
            name="password"
            :form="form"
            label="Password"
            :required="true"
          />

          <!-- Remember Me -->
          <div class="relative flex items-start my-3">
            <CheckboxInput
              v-model="remember"
              class="w-full md:w-1/2"
              name="remember"
              size="small"
              label="Remember me"
            />

            <div class="w-full md:w-1/2 text-right">
              <NuxtLink
                :to="{ name: 'password-reset' }"
                class="text-xs hover:underline text-gray-500 sm:text-sm hover:text-gray-700"
              >
                Forgot your password?
              </NuxtLink>
            </div>
          </div>

          <!-- Submit Button -->
          <v-button
            class="w-full text-center"
            :loading="form.busy || loading"
          >
            Login
          </v-button>

          <v-button
            native-type="button"
            color="white"
            class="space-x-4 mt-4  w-full"
            :loading="false"
            @click.prevent="signInwithGoogle"
          >
            <Icon
              name="devicon:google"
              class="w-4 h-4 -mt-1"
            />
            <span class="mx-2">Sign in with Google</span>
          </v-button>

          <p class="text-center text-gray-500 text-sm text-center mt-4">
            Don't have an account?
            <NuxtLink :to="{ name: 'register' }">
              Register
            </NuxtLink>
          </p>
        </form>
      </div>
    </div>

    <airtable-forms-footer class="border-t z-20" />
  </div>
</template>

<script setup>
import { fetchAllWorkspaces } from '~/stores/workspaces.js'

definePageMeta({
  middleware: 'guest',
  stickyNavbar: true
})
useAirtableFormsSeoMeta({
  title: 'Login'
})

const router = useRouter()
const authStore = useAuthStore()
const workspaceStore = useWorkspacesStore()
const formsStore = useFormsStore()
const providersStore = useOAuthProvidersStore()
const form = useForm({
  email: '',
  password: ''
})
const remember = ref(false)
const loading = ref(false)

async function login () {
  // Submit the form.
  loading.value = true
  form
    .post('login')
    .then(async (data) => {
      // Save the token.
      authStore.setToken(data.token)

      const [userDataResponse, workspacesResponse] = await Promise.all([
        airtableFormsFetch('user'),
        fetchAllWorkspaces()
      ])
      authStore.setUser(userDataResponse)
      workspaceStore.set(workspacesResponse.data.value)

      // Load forms
      formsStore.loadAll(workspaceStore.currentId)

      // Redirect home.
      redirect()
    })
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      loading.value = false
    })
}

function redirect () {
  const intendedUrlCookie = useCookie('intended_url')

  if (intendedUrlCookie.value) {
    router.push({ path: intendedUrlCookie.value })
    useCookie('intended_url').value = null
  } else {
    router.push({ name: 'home' })
  }
}

function signInwithGoogle() {
  providersStore.guestConnect('google', true)
}
</script>
